import { useStytch, useStytchSession, useStytchUser } from "@stytch/react";
import React, { useState, useEffect } from "react";

import UserInfo from "../components/UserInfo";
import Student from '../components/Student';
import Classroom from "../components/Classroom";
import Event from "../components/Event";

import UserType from "../types/UserType";
import EventType from "../types/EventType";

import { createUserProfile, fetchUserProfile, saveUserProfile } from "../functions/UserProfileFunction";
import { fetchAllEvents, createEventWithParams, deleteEvent } from "../functions/EventFunction";
import { fetchAllStudents, createStudent, deleteStudentById, checkInOrOutStudent, updateStudent } from "../functions/StudentFunction";
import { fetchAllClassrooms, createClassroom, deleteClassroomById } from "../functions/ClassroomFunction";

/*
The Profile component is shown to a user that is logged in.

This component renders the full User and Session object for education. 

This component also includes a log out button which is accomplished by making a method call to revoking the existing session
*/
const ProfilePage = () => {
  const stytch = useStytch();
  // Get the Stytch User object if available
  const { user } = useStytchUser();
  // Get the Stytch Session object if available
  const { session } = useStytchSession();

  const [UserProfile, setUserProfile] = useState();
  const [Events, setEvents] = useState([]);
  const [Students, setStudents] = useState([]);
  const [Classrooms, setClassrooms] = useState([]);

  const headers = {
    'Content-Type': 'text/plain'
  };

  useEffect(() => {
    fetchUserProfile(user.user_id, setUserProfile)
      .then(fetchAllStudents(setStudents))
      .then(fetchAllEvents(setEvents))
      .then(fetchAllClassrooms(setClassrooms));
  }, [user, session]);

  return (
    <div className="profile">
      <h1>Profile</h1>

      <button className="primary" onClick={() => createUserProfile(user.user_id, user.emails[0].email, user.name.first_name, UserType.Admin)}>
        Write User To AWS
      </button>

      <div className="card-list xl">
        <UserInfo data={UserProfile} saveCallback={saveUserProfile} />
      </div>

      <h2>AWS Classroom List</h2>
      <button className="primary" onClick={() => createClassroom(
        {
          name: 'Classroom Name',
          location: 'Random Location',
          teacher: [],
          students: []
        }, fetchAllClassrooms
      )}>
        Create New Classroom
      </button>
      <div className="card-list">
        {Classrooms.length > 0 ?
          Classrooms.map(
            classroom => {
              return <Classroom key={classroom.classId} data={classroom} deleteCallback={deleteClassroomById} />
            }
          ) : 'Cannot find any classrooms.'}
      </div>

      <h2>AWS Children List</h2>
      <button className="primary" onClick={() => createStudent(
        {
          name: 'Student Name',
          age: Math.floor(Math.random() * 10),
          gender: Math.random() > 0.5 ? 'Male' : 'Female',
          parent: 'No Parent Assigned',
          classroom: 'No Classroom Assigned'
        }
      )}>
        Create New Child
      </button>

      <div className="card-list">
        {Students.length > 0 ?
          Students.map(
            student => {
              return <Student key={student.childId} data={student} attendanceCallback={checkInOrOutStudent} saveCallback={updateStudent} updeleteCallback={deleteStudentById} />
            }
          ) : 'Cannot find any children.'
        }
      </div>

      <h2>AWS Event Object</h2>
      <button className="primary" onClick={() => {
        var e = document.getElementById("children-for-event");
        createEventWithParams(EventType.Activity, "Some Activity", e.value, null);
      }}>
        Create New Event
      </button>
      <select name="children-list" id="children-for-event">
        {
          Students.length > 0 ?
            Students.map(
              student => {
                return <option key={student.childId} value={student.childId}>{student.name}</option>
              }
            ) : 'None'
        }
      </select>

      <div className="card-list">
        {Events.length > 0 ?
          Events.map(
            event => {
              return <Event key={event.eventId} data={event} deleteCallback={deleteEvent} />
            }
          ) : 'Cannot find any events.'
        }
      </div>

      <hr />

      <p>
        You are logged in, and a Session has been created. The SDK stores the
        Session as a token and a JWT in the browser cookies as{" "}
        <span className="code">stytch_session</span> and{" "}
        <span className="code">stytch_session_jwt</span> respectively.
      </p>
      {/* Revoking the session results in the session being revoked and cleared from browser storage. The user will return to Login.js */}
      <button className="primary" onClick={() => stytch.session.revoke()}>
        Log out
      </button>
    </div>
  );
};

export default ProfilePage;