import React, { useState } from "react";

/* Type */
import EventType from "../types/EventType";

/* UI */
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const Event = ({ data, deleteCallback }) => {
    return (
        <div className="card">
            <div className="container">
                <h3>{data ? data.time : ""}</h3>
                <p>EventId: {data ? data.eventId : ""}</p>
                <p>Child: {data ? data.childId : ""}</p>
                <p>Type: {data ? data.type : ""}</p>
                <p>Description: {data ? data.description : ""}</p>
                {/* <button onClick={() => deleteCallback(data.eventId)}>
                    Delete
                </button> */}
            </div>
        </div>
    )
};

export const CreateEventModal = (props) => {
    const [Student, setStudent] = useState();
    const [Type, setType] = useState();
    const [Description, setDescription] = useState();

    return (
        <Modal {...props} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Creating New Activity
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FloatingLabel label="For Student" controlId="formEventStudent">
                        <Form.Select aria-label="Default select example" onChange={e => setStudent(e.target.value)}>
                            <option>Select Student</option>
                            {
                                Array.isArray(props.studentList) && props.studentList.length > 0
                                    ?
                                    props.studentList.map(
                                        student => {
                                            return <option key={student.key} value={student.key}>{student.text}</option>;
                                        }
                                    )
                                    :
                                    ""
                            }
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel label="Activity Type" controlId="formEventType">
                        <Form.Select aria-label="Default select example" onChange={e => setType(e.target.value)}>
                            <option>Select Type</option>
                            <option value={EventType.Attendence}>{EventType.Attendence}</option>
                            <option value={EventType.Diaper}>{EventType.Diaper}</option>
                            <option value={EventType.Incident}>{EventType.Incident}</option>
                            <option value={EventType.Meal}>{EventType.Meal}</option>
                            <option value={EventType.Nap}>{EventType.Nap}</option>
                            <option value={EventType.Notes}>{EventType.Notes}</option>
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel label="Description" controlId="formEventDescription">
                        <Form.Control as="textarea" placeholder="Description" rows={3} onChange={e => setDescription(e.target.value)} />
                    </FloatingLabel>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup aria-label="Basic Example">
                    <Button variant="success" onClick={() => {
                        props.saveCallback({
                            event_type: Type,
                            event_description: Description,
                            student_id: Student
                        });
                        props.onHide();
                    }}>Send</Button>
                    <Button variant="light" onClick={props.onHide}>Cancel</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

export default Event;