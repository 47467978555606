import React, { useEffect, useState } from 'react';

/* UI */
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const ArrayBuilderItem = (props) => {
    return (
        <div style={props.style} >
            <div style={{ display: 'inline-block', flexBasis: '80%' }}>{props.item}</div>
            <Button style={{ flexBasis: '20%' }} variant='link' size='sm' onClick={() => { props.onDelete(props.itemKey) }}>
                X
            </Button>
        </div>
    );
}

const ArrayBuilderSelectItem = (props) => {
    return (
        <FloatingLabel label={props.label} className="mb-3" controlId={props.controlId}>
            <Form.Select aria-label="Default select example" defaultValue={props.defaultValue} onChange={
                (e) => {
                    if (e.target.value !== props.placeholderOption) {
                        props.onChange(e.target.value);
                        e.target.selectedIndex = 0;
                    }
                }}>
                <option>{props.placeholderOption}</option>
                {
                    props.optionList.length > 0
                        ?
                        props.optionList.map(
                            item => {
                                return <option key={item.key} value={item.key}>{item.text}</option>;
                            }
                        )
                        :
                        <></>
                }
            </Form.Select>
        </FloatingLabel>
    )
}

const ArrayBuilder = (props) => {
    const [ItemList, setItemList] = useState([]);

    useEffect(() => {
        if (Array.isArray(props.defaultValue)) {
            setItemList(props.defaultValue);
        } else {
            setItemList([]);
        }
    }, []);

    const addItem = (itemToAdd) => {
        if (!Array.isArray(ItemList)) {
            return;
        }

        if (ItemList.includes(itemToAdd)) {
            return;
        }

        let newList = [...ItemList, itemToAdd];
        setItemList(newList);

        if (props.updateCallback) {
            props.updateCallback(newList);
        }
    }

    const removeItem = (itemToRemove) => {
        const removeFilter = item => {
            return item != itemToRemove;
        }

        let newList = ItemList.filter(removeFilter);
        setItemList(newList);

        if (props.updateCallback) {
            props.updateCallback(newList);
        }
    }

    const ArrayContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0,
        color: 'lightgrey'
    };

    const ArrayContainerItemStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        boxSizing: 'content-box',
        borderRadius: '1rem',
        border: 'solid lightgrey 1px',
        padding: '0.25rem 0.5rem',
        margin: '0.2rem 0.2rem',
        minWidth: '25%',
        maxWidth: '25%',
        color: 'grey',
        backgroundColor: 'white'
    }

    const FilteredList = Array.isArray(props.optionList) ? props.optionList.filter(
        item => {
            return Array.isArray(ItemList) ? !ItemList.includes(item.key) : true;
        }
    ) : [];

    return (
        <Container style={{
            border: '1px lightgrey solid',
            borderRadius: '1rem',
            marginBottom: '1rem'
        }}>
            <ArrayBuilderSelectItem
                label={props.label}
                controlId={props.controlId}
                onChange={addItem}
                placeholderOption={props.placeholderOption}
                optionList={FilteredList}
            />
            <Container fluid style={ArrayContainerStyle}>
                {
                    Array.isArray(ItemList) && ItemList.length > 0
                        ?
                        ItemList.map(
                            item => {
                                let text = '';
                                if (Array.isArray(props.optionList)) {
                                    props.optionList.forEach(element => {
                                        if (element.key === item) {
                                            text = element.text;
                                        }
                                    });
                                }
                                return <ArrayBuilderItem key={item} item={text} itemKey={item} onDelete={removeItem} style={ArrayContainerItemStyle} />
                            }
                        )
                        :
                        <i>Empty</i>
                }
            </Container>
            {/* <ButtonGroup aria-label="Basic example" size="sm">
                <Button variant="danger" onClick={
                    () => {
                        setItemList([]);
                    }
                }>Clear List</Button>
            </ButtonGroup> */}
        </Container>
    );
}

export default ArrayBuilder;