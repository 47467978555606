import React, { useState, useEffect } from 'react';

/* Stytch */
import { useStytchUser } from '@stytch/react';

/* Function */
import { fetchUserProfile } from '../functions/UserProfileFunction';

/* Type */
import UserType from '../types/UserType';

const ParentJournalPage = ({ user }) => {
    return (
        <></>
    );
}

/* journal page */
const JournalPage = () => {
    const { user } = useStytchUser();

    const [UserProfile, SetUserProfile] = useState();

    const GetPageByUserType = (profile) => {
        if (profile.role === UserType.Parent) {
            return <ParentJournalPage user={profile} />;
        } else {
            return <p>Unsupported User Type.</p>
        }
    }

    useEffect(() => {
        const initialUserProfileFetch = async () => {
            await fetchUserProfile(user.user_id).then(result => SetUserProfile(result));
        }

        initialUserProfileFetch();
    }, [user]);

    return (
        <>
            {UserProfile ?
                GetPageByUserType(UserProfile)
                :
                <p>Still loading User Profile...</p>
            }
        </>
    )
};

export default JournalPage;