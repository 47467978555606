import React from 'react';
import { StytchLogin } from '@stytch/react';
import { Products } from '@stytch/vanilla-js';

import ArrayBuilder from '../components/ArrayBuilder';

/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch

This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
*/
const LoginPage = () => {
  const styles = {
    fontFamily: 'Arial',
    hideHeaderText: true,
    container: {
      width: '100%',
    },
    buttons: {
      primary: {
        backgroundColor: '#4A37BE',
        borderColor: '#4A37BE',
      },
    },
  };

  let redirectLink = window.location.href === "https://localhost:3000" ? window.location.href + 'authenticate' : window.location.href;
  const config = {
    products: [Products.emailMagicLinks],
    emailMagicLinksOptions: {
      loginRedirectURL: redirectLink,
      loginExpirationMinutes: 60,
      //signupRedirectURL: 'http://localhost:3000/authenticate',
      //signupExpirationMinutes: 60,
    },
    passwordOptions: {
      loginRedirectURL: 'http://localhost:3000/authenticate',
      loginExpirationMinutes: 60,
    }
  };

  return (
    <>
      <h2>Welcome to Portal. Please login.</h2>
      <StytchLogin config={config} styles={styles} />

      {/* <ArrayBuilder
        label="Array Builder"
        addLabel="Add Student"
        controlId="formClassStudentArray"
        placeholderOption="Add Student"
        optionList={[
          {
            key: "Felia",
            text: "Felia"
          },
          {
            key: "Siya",
            text: "Siya"
          },
          {
            key: "Luka",
            text: "Luka"
          }
        ]}
        defaultValue={[
          "Felia"
        ]}
        updateCallback={e => { console.log(e) }}
      /> */}
    </>
  )
};

export default LoginPage;