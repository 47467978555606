/* Type */
import SessionStorageType from "../types/SessionStorageType";
import UserType from "../types/UserType";

export const ConcatListToString = (DataList, ListAsString) => {
    let List = JSON.parse(ListAsString);

    let ReturnString = "";
    let bFirstOne = true;
    if (Array.isArray(List)) {
        List.forEach(element => {
            let DisplayText = element;
            if (Array.isArray(DataList)) {
                DataList.forEach(data => {
                    if (data.key === element) {
                        DisplayText = data.text;
                    }
                });
            }
            if (bFirstOne) {
                ReturnString = ReturnString.concat(DisplayText);
                bFirstOne = false;
            } else {
                ReturnString = ReturnString.concat(", ", DisplayText);
            }
        });
    }

    return ReturnString;
}

export const ObtainSessionStorageData = () => {
    if (sessionStorage.getItem(SessionStorageType.Users) === "undefined") {
        return {
            UserList: [],
            AdminList: [],
            TeacherList: [],
            ParentList: [],
            StudentList: [],
            ClassroomList: []
        }
    }

    const StorageUserData = sessionStorage.getItem(SessionStorageType.Users);
    const UserData = JSON.parse(StorageUserData);

    let UserList = [];
    if (Array.isArray(UserData)) {
        UserData.forEach(user => {
            UserList.push(
                {
                    key: user.username,
                    text: user.nickname
                }
            )
        });
    }

    let AdminList = [];
    if (Array.isArray(UserData)) {
        UserData.forEach(user => {
            if (user.role === UserType.Admin) {
                AdminList.push(
                    {
                        key: user.username,
                        text: user.nickname
                    }
                )
            }
        });
    }

    let TeacherList = [];
    if (Array.isArray(UserData)) {
        UserData.forEach(user => {
            if (user.role === UserType.Teacher) {
                TeacherList.push(
                    {
                        key: user.username,
                        text: user.nickname
                    }
                )
            }
        });
    }

    let ParentList = [];
    if (Array.isArray(UserData)) {
        UserData.forEach(user => {
            if (user.role === UserType.Parent) {
                ParentList.push(
                    {
                        key: user.username,
                        text: user.nickname
                    }
                )
            }
        });
    }

    let StudentList = [];
    const StudentData = JSON.parse(sessionStorage.getItem(SessionStorageType.Students));
    if (Array.isArray(StudentData)) {
        StudentData.forEach(student => {
            StudentList.push(
                {
                    key: student.childId,
                    text: student.name
                }
            )
        });
    }

    let ClassroomList = [];
    const ClassroomData = JSON.parse(sessionStorage.getItem(SessionStorageType.Classrooms));
    if (Array.isArray(ClassroomData)) {
        ClassroomData.forEach(classroom => {
            ClassroomList.push(
                {
                    key: classroom.classId,
                    text: classroom.name
                }
            )
        });
    }

    return {
        UserList: UserList,
        AdminList: AdminList,
        TeacherList: TeacherList,
        ParentList: ParentList,
        StudentList: StudentList,
        ClassroomList: ClassroomList
    }
}