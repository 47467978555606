import axios from 'axios';

/* Function */
import { uploadImage } from './FileFunction';

/* Type */
import PostActionType from '../types/PostActionType';

const headers = {
    'Content-Type': 'text/plain'
};

export const fetchAllMessages = async () => {
    try {
        const url = process.env.REACT_APP_AWS_MESSAGES;
        const response = await fetch(url);
        const result = await response.json();

        if (Array.isArray(result)) {
            return result;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching all messages:', error);
    }
};

export const fetchAllMessagesForUserId = async (user_id) => {
    try {
        const url = process.env.REACT_APP_AWS_MESSAGES.concat("?", "id=", user_id);
        const response = await fetch(url);
        const result = await response.json();

        if (Array.isArray(result)) {
            return result;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching all messages:', error);
    }
}

export const createMessage = async (props) => {
    try {
        // if file is not undefined, try upload the file
        if (props.message_file !== undefined) {
            await uploadImage(
                {
                    image: props.message_file
                }
            ).then(result => {
                if (result.success) {
                    props.imageLink = result.imageLink
                }
            })
        }

        const MessageNumber = (Date.now() * 1000) + (Math.floor(Math.random() * 999));
        await axios.post(
            process.env.REACT_APP_AWS_MESSAGES,
            {
                'id': "Message_" + MessageNumber.toString(),
                'time': new Date().toLocaleString(),
                'from': props.message_sender,
                'username': JSON.parse(props.message_receivers)[0],
                'receivers': props.message_receivers,
                'subject': props.message_subject,
                'message': props.message_content,
                'file': props.message_file,
                'imagelink': props.imageLink
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error('Error creating message:', error);
    }
}

export const deleteMessageById = async (message_id) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_MESSAGES,
            {
                'id': message_id,
                'action': PostActionType.Delete
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error('Error deleting message:', error);
    }
}