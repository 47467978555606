import React, { useEffect, useState } from 'react';

/* Stytch */
import { useStytchUser } from '@stytch/react';

/* Component */
import UserInfo, { CreateUserInfoModal } from '../components/UserInfo';
import Classroom, { CreateClassroomModal } from '../components/Classroom';
import Student, { CreateStudentModal } from '../components/Student';
import Event, { CreateEventModal } from '../components/Event';

/* Function */
import { fetchAllUsers, fetchUserProfile, saveUserProfile, createNewUser } from '../functions/UserProfileFunction';
import { createClassroom, deleteClassroomById, fetchAllClassrooms, updateClassroom } from '../functions/ClassroomFunction';
import { checkInOrOutStudent, createStudent, deleteStudentById, fetchAllStudents, fetchStudentsByParentId, updateStudent } from '../functions/StudentFunction';
import { createEventWithParams, fetchAllEvents, fetchAllEventsByStudentIds } from '../functions/EventFunction';

/* Type */
import UserType from '../types/UserType';
import SessionStorageType from '../types/SessionStorageType';

/* UI */
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';

/* home page */
const HomePage = () => {
    const { user } = useStytchUser();

    const [UserProfile, SetUserProfile] = useState();

    const GetHomePageByUserType = (profile) => {
        if (profile.role === UserType.Admin) {
            return <AdminHomePage user={profile} />;
        } else if (profile.role === UserType.Teacher) {
            return <TeacherHomePage user={profile} />;
        } else if (profile.role === UserType.Parent) {
            return <ParentHomePage user={profile} />;
        } else {
            return <p>Unsupported User Type.</p>
        }
    }

    useEffect(() => {
        const initialUserProfileFetch = async () => {
            await fetchUserProfile(user.user_id).then(result => SetUserProfile(result));
        }

        initialUserProfileFetch();
    }, [user]);

    return (
        <>
            {UserProfile ?
                GetHomePageByUserType(UserProfile)
                :
                <p>Still loading User Profile...</p>
            }
        </>
    )
};

const ParentHomePage = ({ user }) => {
    const [Events, setEvents] = useState([]);
    const [Students, setStudents] = useState([]);

    useEffect(() => {
        const initialAllStudentsFetch = async (parent_id) => {
            await fetchStudentsByParentId(parent_id).then(
                result => {
                    setStudents(result);
                }
            );
        }

        initialAllStudentsFetch(user.username);
    }, [user]);

    useEffect(() => {
        const initialAllEventsFetch = async (student_list) => {
            await fetchAllEventsByStudentIds(student_list).then(
                result => {
                    setEvents(result);
                });
        }


        if (Array.isArray(Students)) {
            let student_list = [];
            Students.forEach(
                student => {
                    student_list.push(student.childId);
                }
            )
            initialAllEventsFetch(student_list);
        }
    }, [Students])

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    Welcome, {user ? user.nickname : 'user'}.
                </Card.Header>
                <Card.Body>
                    This is home page for parents.
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    Your Children:
                </Card.Header>
                <Card.Body>
                    {
                        Students.length > 0
                            ?
                            Students.map(
                                student => {
                                    return <p key={student.childId}>{student.name} - {student.attendance}</p>;
                                }
                            )
                            :
                            "No Students Found."
                    }
                </Card.Body>
            </Card>
            <Container fluid>
                <hr />
                <h3>Event List</h3>
                <hr />
                {
                    Events.length > 0
                        ?
                        Events.map(
                            event => {
                                return <Event key={event.eventId} data={event} deleteCallback={() => { }} />
                            }
                        )
                        :
                        "No Events Found."
                }
            </Container>
        </Container>
    );
}

const TeacherHomePage = ({ user }) => {
    const [Students, setStudents] = useState([]);

    const [NewEventModalShow, setNewEventModalShow] = useState(false);

    useEffect(() => {
        const initialAllStudentsFetch = async () => {
            await fetchAllStudents().then(
                result => {
                    setStudents(result);
                });
        }

        initialAllStudentsFetch();
    }, []);

    const onSavingStudent = async (props) => {
        await updateStudent(props);
        await fetchAllStudents().then(result => setStudents(result));
    }

    const onDeletingStudent = async (student_id) => {
        await deleteStudentById(student_id);
        await fetchAllStudents().then(result => setStudents(result));
    }

    const onCheckInStudent = async (student_id, bCheckIn) => {
        await checkInOrOutStudent(student_id, bCheckIn);
        await fetchAllStudents().then(result => setStudents(result));
    }

    const onCreatingEvent = async (props) => {
        await createEventWithParams(props);
    }

    let student_list = [];
    if (Array.isArray(Students)) {
        Students.forEach(
            student => {
                student_list.push(
                    {
                        key: student.childId,
                        text: student.name
                    }
                )
            }
        )
    }

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    Welcome, {user ? user.nickname : 'user'}.
                </Card.Header>
                <Card.Body>
                    This is home page for teachers.
                </Card.Body>
            </Card>
            <Container fluid>
                <hr />
                <h3>Student List</h3>
                <hr />
                <CreateEventModal show={NewEventModalShow} onHide={() => setNewEventModalShow(false)} studentList={student_list} saveCallback={onCreatingEvent} />
                <ButtonGroup>
                    <Button variant='primary' onClick={() => setNewEventModalShow(true)}>Add Activity</Button>
                </ButtonGroup>
                <Table striped style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Student Name</th>
                            <th>Age</th>
                            <th>Gender</th>
                            <th>Parents</th>
                            <th>Classroom</th>
                            <th>Attendance</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Students.length > 0 ?
                            Students.map(
                                student => {
                                    return <Student key={student.childId} data={student} attendanceCallback={onCheckInStudent} saveCallback={onSavingStudent} deleteCallback={onDeletingStudent} />
                                }
                            )
                            :
                            <tr><td>No Students Found.</td></tr>
                        }
                    </tbody>
                </Table>
            </Container>
        </Container>
    );
}

const AdminHomePage = ({ user }) => {
    const [Users, setUsers] = useState([]);
    const [Classrooms, setClassrooms] = useState([]);
    const [Students, setStudents] = useState([]);

    const [NewUserModalShow, setNewUserModalShow] = useState(false);
    const [NewClassroomModalShow, setNewClassroomModalShow] = useState(false);
    const [NewStudentModalShow, setNewStudentModalShow] = useState(false);

    useEffect(() => {
        const initialAllUsersFetch = async () => {
            await fetchAllUsers().then(
                result => {
                    setUsers(result);
                    sessionStorage.setItem(SessionStorageType.Users, JSON.stringify(result));
                });
        }

        const initialAllStudentsFetch = async () => {
            await fetchAllStudents().then(
                result => {
                    setStudents(result);
                    sessionStorage.setItem(SessionStorageType.Students, JSON.stringify(result));
                });
        }

        const initialAllClassroomsFetch = async () => {
            await fetchAllClassrooms().then(
                result => {
                    setClassrooms(result);
                    sessionStorage.setItem(SessionStorageType.Classrooms, JSON.stringify(result));
                });
        }

        initialAllUsersFetch();
        initialAllClassroomsFetch();
        initialAllStudentsFetch();
    }, []);

    const onCreatingUser = async (props) => {
        await createNewUser(props);
        await fetchAllUsers().then(result => setUsers(result));
    }

    const onSavingUser = async (props) => {
        await saveUserProfile(props);
        await fetchAllUsers().then(result => setUsers(result));
    }

    // const onDeletingUser = async (user_id) => {
    //     await deleteUserById(user_id);
    //     await fetchAllUsers().then(result => setUsers(result));
    // }

    const onCreatingClassroom = async (props) => {
        await createClassroom(props);
        await fetchAllClassrooms().then(result => setClassrooms(result));
    }

    const onSavingClassroom = async (props) => {
        await updateClassroom(props);
        await fetchAllClassrooms().then(result => setClassrooms(result));
    }

    const onDeletingClassroom = async (class_id) => {
        await deleteClassroomById(class_id);
        await fetchAllClassrooms().then(result => setClassrooms(result));
    }

    const onCreatingStudent = async (props) => {
        await createStudent(props);
        await fetchAllStudents().then(result => setStudents(result));
    }

    const onSavingStudent = async (props) => {
        await updateStudent(props);
        await fetchAllStudents().then(result => setStudents(result));
    }

    const onDeletingStudent = async (student_id) => {
        await deleteStudentById(student_id);
        await fetchAllStudents().then(result => setStudents(result));
    }

    const onCheckInStudent = async (student_id, bCheckIn) => {
        await checkInOrOutStudent(student_id, bCheckIn);
        await fetchAllStudents().then(result => setStudents(result));
    }

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <h1>Welcome, {user ? user.nickname : 'user'}.</h1>
                </Card.Header>
                <Card.Body>
                    <p>This is home page for admins.</p>
                </Card.Body>
            </Card>

            <Container fluid>
                <hr />
                <h3>User List</h3>
                <hr />
                <Button variant='primary' onClick={() => setNewUserModalShow(true)}>New User</Button>
                <CreateUserInfoModal show={NewUserModalShow} onHide={() => setNewUserModalShow(false)} saveCallback={onCreatingUser} />
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>User Role</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Users.length > 0 ?
                            Users.map(
                                user => {
                                    return <UserInfo key={user.username} data={user} saveCallback={onSavingUser} />
                                }
                            )
                            :
                            <tr><td>No Users Found.</td></tr>
                        }
                    </tbody>
                </Table>
            </Container>

            <Container fluid>
                <hr />
                <h3>Classroom List</h3>
                <hr />
                <Button variant='primary' onClick={() => setNewClassroomModalShow(true)}>New Classroom</Button>
                <CreateClassroomModal show={NewClassroomModalShow} onHide={() => setNewClassroomModalShow(false)} saveCallback={onCreatingClassroom} />
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>Class Name</th>
                            <th>Location</th>
                            <th>Teachers</th>
                            <th>Students</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Classrooms.length > 0 ?
                            Classrooms.map(
                                classroom => {
                                    return <Classroom key={classroom.classId} data={classroom} saveCallback={onSavingClassroom} deleteCallback={onDeletingClassroom} />
                                }
                            )
                            :
                            <tr><td>No Classrooms Found.</td></tr>
                        }
                    </tbody>
                </Table>
            </Container>

            <Container fluid>
                <hr />
                <h3>Student List</h3>
                <hr />
                <Button variant='primary' onClick={() => setNewStudentModalShow(true)}>New Student</Button>
                <CreateStudentModal show={NewStudentModalShow} onHide={() => setNewStudentModalShow(false)} saveCallback={onCreatingStudent} />
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>Student Name</th>
                            <th>Age</th>
                            <th>Gender</th>
                            <th>Parents</th>
                            <th>Classroom</th>
                            <th>Attendance</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Students.length > 0 ?
                            Students.map(
                                student => {
                                    return <Student key={student.childId} data={student} attendanceCallback={onCheckInStudent} saveCallback={onSavingStudent} deleteCallback={onDeletingStudent} />
                                }
                            )
                            :
                            <tr><td>No Students Found.</td></tr>
                        }
                    </tbody>
                </Table>
            </Container>
        </Container>
    );
}

export default HomePage;