import axios from 'axios';

import PostActionType from "../types/PostActionType";
import AttendanceType from '../types/AttendanceType';

const headers = {
    'Content-Type': 'text/plain'
};

export const fetchAllStudents = async () => {
    try {
        const url = process.env.REACT_APP_AWS_CHILDREN.concat("?id=all");
        const response = await fetch(url);
        const result = await response.json();

        if (Array.isArray(result)) {
            return result;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching all students:', error);
    }
};

export const fetchStudentById = async (student_id) => {
    try {
        const url = process.env.REACT_APP_AWS_CHILDREN.concat("?id=", student_id.toString());
        const response = await fetch(url);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(`Error fetching student of ${student_id}:`, error);
    }
}

export const fetchStudentsByParentId = async (parent_id) => {
    try {
        const url = process.env.REACT_APP_AWS_CHILDREN.concat("?parent=", parent_id.toString());
        const response = await fetch(url);
        const result = await response.json();

        if (Array.isArray(result)) {
            return result;
        } else {
            return [];
        }
    } catch (error) {
        console.error(`Error fetching students of parent ${parent_id}:`, error);
    }
}

export const fetchStudentsByClassId = async (class_id) => {
    try {
        const url = process.env.REACT_APP_AWS_CHILDREN.concat("?class=", class_id.toString());
        const response = await fetch(url);
        const result = await response.json();

        if (Array.isArray(result)) {
            return result;
        } else {
            return [];
        }
    } catch (error) {
        console.error(`Error fetching students of class ${class_id}:`, error);
    }
}

export const createStudent = async (props) => {
    try {
        const StudentNumber = Math.round(Math.random() * 1000);
        await axios.post(
            process.env.REACT_APP_AWS_CHILDREN,
            {
                'id': StudentNumber.toString(),
                'name': props.student_name,
                'age': props.student_age,
                'gender': props.student_gender,
                'parents': props.student_parents,
                'classroom': props.student_classroom,
                'attendance': props.student_attendance
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error('Error creating student:', error);
    }
}

export const deleteStudentById = async (student_id) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_CHILDREN,
            {
                'id': student_id,
                'action': PostActionType.Delete
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error('Error deleting student:', error);
    }
}

export const checkInOrOutStudent = async (student_id, bCheckIn) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_CHECKIN,
            {
                'id': student_id,
                'attendance': bCheckIn ? AttendanceType.Present : AttendanceType.NotCheckedIn
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error('Error updating student attendance status:', error);
    }
}

export const updateStudent = async (prop) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_CHILDREN,
            {
                'id': prop.student_id,
                'action': PostActionType.Update,
                'name': prop.student_name,
                'age': prop.student_age,
                'gender': prop.student_gender,
                'parents': prop.student_parents,
                'classroom': prop.student_classroom,
                'attendance': prop.student_attendance
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error('Error updating student attendance status:', error);
    }
}