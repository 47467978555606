const EventType = {
    Attendence: "Attendence",
    Diaper: "Diaper",
    Meal: "Meal",
    Incident: "Incident",
    Nap: "Nap",
    Activity: "Activity",
    Notes: "Notes"
}

export default EventType;