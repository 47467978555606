import React, { useEffect, useState } from "react";

/* Function */
import { ConcatListToString, ObtainSessionStorageData } from "../functions/SessionStorageFunction";

/* UI */
import ArrayBuilder from "./ArrayBuilder";
import Button from 'react-bootstrap/Button';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const Message = ({ data, deleteCallback }) => {
    return (
        <Card style={{ width: '25rem' }}>
            <Card.Header>
                {data ? data.time : ""}
            </Card.Header>
            <Card.Body>
                <Card.Title>
                    {data ? data.subject : ""}
                </Card.Title>
                <Card.Text>
                    From: {data ? data.sender : ""}
                    Message: <pre>{data ? data.message : ""}</pre>
                </Card.Text>
                <Button variant="danger" onClick={() => deleteCallback(data.messageId)}>
                    Delete
                </Button>
                <Card.Footer className="text-muted">MessageId: {data ? data.messageId : ""}</Card.Footer>
            </Card.Body>
        </Card>
    )
};

export const DisplayMessageTableRow = ({ data, openCallback, deleteCallback, userList }) => {
    const [ShowMessageDetails, setShowMessageDetails] = useState(false);

    return (
        <>
            <ShowMessageModal
                show={ShowMessageDetails}
                onHide={() => setShowMessageDetails(false)}
                deleteCallback={deleteCallback}
                data={data}
                userList={userList}
            />
            <tr>
                <td>{data ? data.time : ''}</td>
                <td>{data ? ConcatListToString(userList, `["${data.from}"]`) : ''}</td>
                <td><Button variant="link" onClick={() => setShowMessageDetails(true)} style={{ border: 0, padding: 0 }}>{data ? data.subject : ''}</Button></td>
                <td><Button variant="danger" onClick={() => deleteCallback(data.messageId)}>Delete</Button></td>
            </tr>
        </>
    )
}

export const ShowMessageModal = (props) => {
    return (
        <Modal {...props} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.data ? props.data.subject : "No Subject"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    From: {props.data.from ? ConcatListToString(props.userList, `["${props.data.from}"]`) : "No Sender"}
                </p>
                <p>
                    Time: {props.data.time ? props.data.time : "No Time"}
                </p>
                <p>
                    Message:
                </p>
                <pre>
                    {props.data.message ? props.data.message : "No Message"}
                </pre>
                <p>
                    Attachment:
                    {
                        props.data.imagelink !== undefined
                            ?
                            <>
                                <br />
                                {
                                    props.data.imagelink.includes("pdf")
                                        ?
                                        <a href={props.data.imagelink}>Link To Attachment</a>
                                        :
                                        <img src={props.data.imagelink} style={{ maxWidth: "100%", height: "auto" }} />
                                }
                            </>
                            :
                            ""
                    }
                </p>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button variant="light" onClick={props.onHide}>Close</Button>
                    <Button variant="danger" onClick={props.deleteCallback}>Delete</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal >
    );
}

export const CreateMessageModal = (props) => {
    const [Receivers, setReceivers] = useState([]);
    const [Subject, setSubject] = useState();
    const [Message, setMessage] = useState();
    const [File, setFile] = useState();
    const [PreviewURL, setPreviewURL] = useState();

    //const { UserList } = ObtainSessionStorageData();

    return (
        <Modal {...props} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Creating New Message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <ArrayBuilder label="To" controlId="formMessageReceivers" placeholderOption="Select Receivers" optionList={props.userList} updateCallback={setReceivers} />
                    <FloatingLabel label="Subject" className="mb-3" controlId="formMessageSubject">
                        <Form.Control type="text" placeholder="Subject" onChange={e => setSubject(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Message" className="mb-3" controlId="formMessageMessage">
                        <Form.Control as="textarea" placeholder="Message" rows={3} onChange={e => setMessage(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Attachment" className="mb-3" controlId="formMesssageAttachment">
                        <Form.Control type="file" accept="image/png, image/jpeg, .pdf" onChange={e => {
                            setFile(e.target.files[0]);
                            setPreviewURL(URL.createObjectURL(e.target.files[0]));
                            console.log(e.target.files[0].type);
                        }} />
                        {
                            PreviewURL !== undefined
                                ?
                                <img src={PreviewURL} style={{ maxWidth: "100%", height: "auto" }} />
                                :
                                ""
                        }
                    </FloatingLabel>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup aria-label="Basic Example">
                    <Button variant="success" onClick={() => {
                        props.saveCallback({
                            message_receivers: JSON.stringify(Receivers),
                            message_subject: Subject,
                            message_content: Message,
                            message_file: PreviewURL
                        });
                        props.onHide();
                        setPreviewURL(undefined);
                    }}>Send</Button>
                    <Button variant="light" onClick={() => {
                        setPreviewURL(undefined);
                        props.onHide();
                    }}>Cancel</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal >
    );
}

export const CreateMessage = ({ callback }) => {

    const [Receiver, setReceiver] = useState();
    const [Title, setTitle] = useState();
    const [Message, setMessage] = useState();
    const [File, setFile] = useState();

    const handleUpload = async () => {
        // const formData = new FormData();
        // formData.append('receiver', Receiver);
        // formData.append('title', Title);
        // formData.append('message', Message);
        // formData.append('file', File);

        callback(Receiver, Title, Message);
    }

    return (
        <Card style={{ width: '25rem' }}>
            <Card.Body>
                <Card.Title>Create New Message</Card.Title>
                <Card.Text>
                    Use this form to create new messages.
                </Card.Text>
                <Form>
                    <Form.Group className="mb-3" controlId="formUserId">
                        <Form.Label>To: </Form.Label>
                        <Form.Control type="text" placeholder="Enter UserId" onChange={e => setReceiver(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formSubject">
                        <Form.Label>Subject: </Form.Label>
                        <Form.Control type="text" placeholder="Enter Subject" onChange={e => setTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMessage">
                        <Form.Label>Message: </Form.Label>
                        <Form.Control as="textarea" rows={3} onChange={e => setMessage(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formFile">
                        <Form.Label>File: </Form.Label>
                        <Form.Control type="file" accept="image/png, image/jpeg, .pdf" onChange={e => setFile(e.target.files[0])} />
                    </Form.Group>
                </Form>
                <Button variant="primary" onClick={handleUpload}>
                    Send Message
                </Button>
            </Card.Body>
        </Card>
    )
}

export default Message;