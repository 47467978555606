import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.min.css';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from '@stytch/vanilla-js';
import TokenAuth from "./components/TokenAuth";

import { BrowserRouter } from 'react-router-dom';


// We initialize the Stytch client using our project's public token which can be found in the Stytch dashboard
const stytch = new StytchUIClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* StytchProvider gives our app access to the Stytch client */}
    <StytchProvider stytch={stytch}>
      <TokenAuth>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </TokenAuth>
    </StytchProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
