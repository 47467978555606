import React, { useEffect, useState } from "react";

/* Function */
import { ConcatListToString, ObtainSessionStorageData } from "../functions/SessionStorageFunction";

/* Type */
import AttendenceType from "../types/AttendanceType";
import GenderType from "../types/GenderType";
import SessionStorageType from "../types/SessionStorageType";
import UserType from "../types/UserType";

/* UI */
import ArrayBuilder from './ArrayBuilder';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const DisplayStudentTableRow = ({ data, editCallback, attendanceCallback }) => {
    const { ParentList, ClassroomList } = ObtainSessionStorageData();

    return (
        <tr>
            <td>{data ? data.name : ''}</td>
            <td>{data ? data.age : ''}</td>
            <td>{data ? data.gender : ''}</td>
            <td>{data ? ConcatListToString(ParentList, data.parents ? JSON.stringify(data.parents) : '[]') : ''}</td>
            <td>{data ? ConcatListToString(ClassroomList, data.classroom ? `["${data.classroom}"]` : '[]') : ''}</td>
            <td>{data ? data.attendance : ''}</td>
            <td>
                <ButtonGroup size="sm">
                    {
                        data.attendance === AttendenceType.Present ?
                            <Button variant="warning" onClick={() => attendanceCallback(data.childId, false)}>Check Out</Button>
                            :
                            <Button variant="success" onClick={() => attendanceCallback(data.childId, true)}>Check In</Button>
                    }
                    <Button variant="light" onClick={editCallback}>Edit</Button>
                </ButtonGroup>
            </td>
        </tr>
    );
}

const EditStudentModal = (props) => {
    const [Name, setName] = useState();
    const [Age, setAge] = useState();
    const [Gender, setGender] = useState();
    const [Parents, setParents] = useState();
    const [Classroom, setClassroom] = useState();
    const [Attendance, setAttendance] = useState();

    const { ParentList, ClassroomList } = ObtainSessionStorageData();

    useEffect(() => {
        setName(props.data.name);
        setAge(props.data.age);
        setGender(props.data.gender);
        setParents(props.data.parents);
        setClassroom(props.data.classroom);
        setAttendance(props.data.attendance);
    }, []);

    return (
        <Modal {...props} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Editing Student - {Name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FloatingLabel label="Student Name" className="mb-3" controlId="formStudentName">
                        <Form.Control type="text" defaultValue={props.data.name} onChange={e => setName(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Age" className="mb-3" controlId="formStudentAge">
                        <Form.Control type="number" defaultValue={props.data.age} onChange={e => setAge(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Gender" className="mb-3" controlId="formStudentGender">
                        <Form.Select aria-label="Default select example" defaultValue={props.data.gender} onChange={e => setGender(e.target.value)}>
                            <option>Select Gender</option>
                            <option value={GenderType.Female}>{GenderType.Female}</option>
                            <option value={GenderType.Male}>{GenderType.Male}</option>
                            <option value={GenderType.Other}>{GenderType.Other}</option>
                        </Form.Select>
                    </FloatingLabel>
                    <ArrayBuilder label="Add Parents" controlId="formStudentParents" placeholderOption="Select Parent" optionList={ParentList} defaultValue={Parents} updateCallback={setParents} />
                    <FloatingLabel label="Classroom" className="mb-3" controlId="formStudentClassroom">
                        <Form.Select aria-label="Default select example" defaultValue={props.data.classroom} onChange={e => setClassroom(e.target.value)}>
                            <option>Select Classroom</option>
                            {
                                ClassroomList.length > 0
                                    ?
                                    ClassroomList.map(
                                        classroom => {
                                            return <option key={classroom.key} value={classroom.key}>{classroom.text}</option>
                                        }
                                    )
                                    :
                                    ""
                            }
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel label="Attendance" className="mb-3" controlId="formStudentAttendance">
                        <Form.Select aria-label="Default select example" defaultValue={props.data.attendance} onChange={e => setAttendance(e.target.value)}>
                            <option>Select Attendance</option>
                            <option value={AttendenceType.NotCheckedIn}>{AttendenceType.NotCheckedIn}</option>
                            <option value={AttendenceType.Present}>{AttendenceType.Present}</option>
                            <option value={AttendenceType.Appointment}>{AttendenceType.Appointment}</option>
                            <option value={AttendenceType.Sick}>{AttendenceType.Sick}</option>
                            <option value={AttendenceType.Vacation}>{AttendenceType.Vacation}</option>
                        </Form.Select>
                    </FloatingLabel>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup aria-label="Basic example">
                    <Button variant="success" onClick={() => {
                        props.saveCallback({
                            student_id: props.data.childId,
                            student_name: Name,
                            student_age: Age,
                            student_gender: Gender,
                            student_parents: Parents,
                            student_classroom: Classroom,
                            student_attendance: Attendance
                        });
                        props.onHide();
                    }}>Save</Button>
                    <Button variant="light" onClick={props.onHide}>Cancel</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

export const CreateStudentModal = (props) => {
    const [Name, setName] = useState();
    const [Age, setAge] = useState();
    const [Gender, setGender] = useState();
    const [Parents, setParents] = useState();
    const [Classroom, setClassroom] = useState();

    const { ParentList, ClassroomList } = ObtainSessionStorageData();

    return (
        <Modal {...props} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Creating New Student
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FloatingLabel label="Student Name" className="mb-3" controlId="formStudentName">
                        <Form.Control type="text" placeholder="Student Name" onChange={e => setName(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Age" className="mb-3" controlId="formStudentAge">
                        <Form.Control type="number" placeholder="1" onChange={e => setAge(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Gender" className="mb-3" controlId="formStudentGender">
                        <Form.Select aria-label="Default select example" onChange={e => setGender(e.target.value)}>
                            <option>Select Gender</option>
                            <option value={GenderType.Female}>{GenderType.Female}</option>
                            <option value={GenderType.Male}>{GenderType.Male}</option>
                            <option value={GenderType.Other}>{GenderType.Other}</option>
                        </Form.Select>
                    </FloatingLabel>
                    <ArrayBuilder label="Add Parents" controlId="formStudentParents" placeholderOption="Select Parent" optionList={ParentList} updateCallback={setParents} />
                    <FloatingLabel label="Classroom" className="mb-3" controlId="formStudentClassroom">
                        <Form.Select aria-label="Default select example" onChange={e => setClassroom(e.target.value)}>
                            <option>Select Classroom</option>
                            {
                                ClassroomList.length > 0
                                    ?
                                    ClassroomList.map(
                                        classroom => {
                                            return <option key={classroom.key} value={classroom.key}>{classroom.text}</option>
                                        }
                                    )
                                    :
                                    ""
                            }
                        </Form.Select>
                    </FloatingLabel>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup aria-label="Basic example">
                    <Button variant="success" onClick={() => {
                        props.saveCallback({
                            student_name: Name,
                            student_age: Age,
                            student_gender: Gender,
                            student_parents: Parents,
                            student_classroom: Classroom,
                            student_attendance: AttendenceType.NotCheckedIn
                        });
                        props.onHide();
                    }}>Save</Button>
                    <Button variant="light" onClick={props.onHide}>Cancel</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

const DisplayStudent = ({ data, editCallback, attendanceCallback, deleteCallback }) => {
    return (
        <Card style={{ width: '35rem' }}>
            <Card.Body>
                <Card.Title>
                    {data ? data.name : ''}
                </Card.Title>
                <Card.Text>Gender: {data ? data.gender : "No Gender Assigned."}</Card.Text>
                <Card.Text>Age: {data ? data.age : "No Age Assigned."}</Card.Text>
                <Card.Text>Parent: {data ? data.parent : "No Parent Assigned."}</Card.Text>
                <Card.Text>Classroom: {data ? data.classroom : "No Classroom Assigned."}</Card.Text>
                <Card.Text>Attendance: {data ? data.attendance : "No Attendance Status Assigned."}</Card.Text>
                <ButtonGroup aria-label="Basic example" size="sm">
                    <Button variant="secondary" onClick={editCallback}>Edit</Button>
                    {
                        data.attendance === AttendenceType.Present ?
                            <Button variant="warning" onClick={() => attendanceCallback(data.childId, false)}>Check Out</Button>
                            :
                            <Button variant="success" onClick={() => attendanceCallback(data.childId, true)}>Check In</Button>
                    }
                    <Button variant="danger" onClick={() => deleteCallback(data.childId)}>Delete</Button>
                </ButtonGroup>
            </Card.Body>
            <Card.Footer>
                {data ? data.childId : ''}
            </Card.Footer>
        </Card>
    )
}

const EditStudent = ({ data, saveCallback, cancelCallback, deleteCallback }) => {

    const [Name, setName] = useState();
    const [Gender, setGender] = useState();
    const [Age, setAge] = useState();
    const [Parent, setParent] = useState();
    const [Classroom, setClassroom] = useState();
    const [Attendance, setAttendance] = useState();

    useEffect(() => {
        setName(data.name);
        setGender(data.gender);
        setAge(data.age);
        setParent(data.parent);
        setClassroom(data.classroom);
        setAttendance(data.attendance);
    }, [data]);

    return (
        <Card style={{ width: '35rem' }}>
            <Card.Header>
                Editing...
            </Card.Header>
            <Card.Body>
                <Form>
                    <FloatingLabel label="Name" className="mb-3" controlId="formStudentName">
                        <Form.Control type="text" defaultValue={data.name} onChange={e => setName(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Gender" className="mb-3" controlId="formStudentGender">
                        <Form.Select aria-label="Default select example" defaultValue={data.gender} onChange={e => setGender(e.target.value)}>
                            <option>Select Gender</option>
                            <option value={GenderType.Male}>{GenderType.Male}</option>
                            <option value={GenderType.Female}>{GenderType.Female}</option>
                            <option value={GenderType.Other}>{GenderType.Other}</option>
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel label="Age" className="mb-3" controlId="formStudentAge">
                        <Form.Control type="number" defaultValue={data.age} onChange={e => setAge(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Parent" className="mb-3" controlId="formParentId">
                        <Form.Control type="text" defaultValue={data.parent} onChange={e => setParent(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Classroom" className="mb-3" controlId="formClassId">
                        <Form.Control type="text" defaultValue={data.classroom} onChange={e => setClassroom(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Attendance" className="mb-3" controlId="formAttendance">
                        <Form.Select aria-label="Default select example" defaultValue={data.attendance} onChange={e => setAttendance(e.target.value)}>
                            <option>Select Attendance</option>
                            <option value={AttendenceType.NotCheckedIn}>{AttendenceType.NotCheckedIn}</option>
                            <option value={AttendenceType.Present}>{AttendenceType.Present}</option>
                            <option value={AttendenceType.Appointment}>{AttendenceType.Appointment}</option>
                            <option value={AttendenceType.Sick}>{AttendenceType.Sick}</option>
                            <option value={AttendenceType.Vacation}>{AttendenceType.Vacation}</option>
                        </Form.Select>
                    </FloatingLabel>
                </Form>
                <ButtonGroup aria-label="Basic example" size="sm">
                    <Button variant="success" onClick={() => {
                        saveCallback({
                            id: data.childId,
                            name: Name,
                            gender: Gender,
                            age: Age,
                            parent: Parent,
                            classroom: Classroom,
                            attendance: Attendance
                        });
                        cancelCallback();
                    }}>Save</Button>
                    <Button variant="secondary" onClick={cancelCallback}>Cancel</Button>
                    <Button variant="danger" onClick={() => deleteCallback(data.childId)}>Delete</Button>
                </ButtonGroup>
            </Card.Body>
            <Card.Footer>
                {data ? data.childId : ''}
            </Card.Footer>
        </Card>
    )
}

const Student = ({ data, attendanceCallback, saveCallback, deleteCallback }) => {

    const [Editing, setEditing] = useState(false);

    return (
        <>
            <EditStudentModal
                data={data}
                show={Editing}
                onHide={() => setEditing(false)}
                saveCallback={saveCallback}
                deleteCallback={deleteCallback} />
            <DisplayStudentTableRow
                data={data}
                editCallback={() => setEditing(true)}
                attendanceCallback={attendanceCallback} />
        </>
    )
};

export default Student;