import './App.css';

import { useStytch, useStytchUser } from "@stytch/react";

import { Route, Routes } from 'react-router-dom';

/* Component */
import LoginPage from "./pages/LoginPage";
import HomePage from './pages/HomePage';
import ProfilePage from "./pages/ProfilePage";
import MessagePage from './pages/MessagePage';
import JournalPage from './pages/JournalPage';

/* UI */
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const App = () => {
  // The useStytchUser hook will return the existing Stytch User object if one exists
  const stytch = useStytch();
  const { user } = useStytchUser();

  const ActiveLocation = window.location.pathname;

  if (user) {
    return (
      <>
        <Navbar bg="light" data-bs-theme="light" expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="/">
              <img
                alt=""
                src="https://kidtopiaedu.ca/landing/wp-content/uploads/2024/09/kid-logo-fixed-512-200x200.png"
                width="90"
                height="90"
                className="d-inline-block align-center"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav fill variant="tabs" activeKey={ActiveLocation} className="me-auto">
                <Nav.Item>
                  <Nav.Link href="/" eventKey="/">Home</Nav.Link>
                </Nav.Item>
                <Nav.Link href="/profile" eventKey="/profile" disabled>Profile</Nav.Link>
                <Nav.Item>
                  <Nav.Link href="/message" eventKey="/message">Message</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/journal" eventKey="/journal">Journal</Nav.Link>
                </Nav.Item>
                {/* <NavDropdown title="More" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                  Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                  Separated link
                  </NavDropdown.Item>
                  </NavDropdown> */}
                <Nav.Link onClick={() => stytch.session.revoke()}>Log out</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* Routes */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/message" element={<MessagePage />} />
          <Route path="/journal" element={<JournalPage />} />
        </Routes>
      </>
    )
  } else {
    return (
      <>
        <Navbar bg="light" data-bs-theme="light" className="bg-body-tertiary">
          <Navbar.Brand href="/">
            <img
              alt=""
              src="https://kidtopiaedu.ca/landing/wp-content/uploads/2024/09/kid-logo-fixed-512-200x200.png"
              width="90"
              height="90"
              className="d-inline-block align-center"
            />
          </Navbar.Brand>
        </Navbar>
        <Container>
          <LoginPage />
        </Container>
      </>
    );
  }
};

export default App;