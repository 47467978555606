import axios from "axios";

export const uploadImage = async (props) => {
    console.log("Upload Image Started.");

    let bSuccess = false;

    let result = await fetch(props.image);
    let blob = await result.blob();

    let fileType;
    switch (blob.type) {
        case "image/png":
            fileType = "png";
            break;
        case "image/jpeg":
            fileType = "jpg";
            break;
        case "application/pdf":
            fileType = "pdf";
            break;
        default:
            return;
    }

    console.log(fileType);

    const s3Response = await axios.get(
        process.env.REACT_APP_AWS_FILES.concat("?actionType=putObject&fileType=", fileType)
    );

    console.log('Response: ', s3Response.data);
    console.log('Uploading: ', blob);

    console.log('Uploading to: ', s3Response.data.uploadURL);

    await axios.put(s3Response.data.uploadURL,
        blob,
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
    ).then(response => bSuccess = response.status === 200);

    return {
        imageLink: s3Response.data.uploadURL.split("?")[0],
        success: bSuccess
    };
}

export const getImageUrl = async (props) => {
    console.log("Get Image URL Started.");

    const fileType = props.image.type == "image/png" ? "png" : "image/jpeg";

    const s3Response = await axios.get(
        process.env.REACT_APP_AWS_FILES.concat("?actionType=getObject&fileType=", fileType)
    );

    console.log('Response: ', s3Response.data);

    return true;
}