import React, { useEffect, useState } from "react";

/* Function */
import { ObtainSessionStorageData } from "../functions/SessionStorageFunction";
import { sendUserMagicLink } from "../functions/UserProfileFunction";

/* Type */
import UserType from "../types/UserType";

/* UI */
import ArrayBuilder from "./ArrayBuilder";
import Button from 'react-bootstrap/Button';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from 'react-bootstrap/Card';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const DisplayUserInfoTableRow = ({ data, editCallback, magicLinkCallback }) => {
    return (
        <tr>
            <td>{data ? data.nickname : ''}</td>
            <td>{data ? data.email : "No Email Assigned."}</td>
            <td>{data ? data.role : "No Role Assigned."}</td>
            {/* <td>{data ? data.child : "No Children Assigned."}</td> */}
            {/* <td>{data ? data.username : ''}</td> */}
            <td>
                <Button variant="light" onClick={editCallback}>Edit</Button>
                <Button variant="info" onClick={magicLinkCallback}>Send Magic Link</Button>
            </td>
        </tr>
    );
}

const DisplayUserInfoCard = ({ data, editCallback }) => {
    return (
        <Card style={{ width: '35rem' }}>
            <Card.Body>
                <Card.Title>
                    {data ? data.nickname : ''}
                </Card.Title>
                <Card.Text>Email: {data ? data.email : "No Email Assigned."}</Card.Text>
                <Card.Text>Child: {data ? data.child : "No Children Assigned."}</Card.Text>
                <Card.Text>Role: {data ? data.role : "No Role Assigned."}</Card.Text>
                <ButtonGroup aria-label="Basic example" size="sm">
                    <Button variant="secondary" onClick={editCallback}>Edit</Button>
                </ButtonGroup>
            </Card.Body>
            <Card.Footer>
                {data ? data.username : ''}
            </Card.Footer>
        </Card>
    )
}

const EditUserInfoCard = ({ data, saveCallback, cancelCallback, deleteCallback }) => {

    const [Name, setName] = useState();
    const [Child, setChild] = useState();
    const [Role, setRole] = useState();

    useEffect(() => {
        setName(data.nickname);
        setChild(data.child);
        setRole(data.role);
    }, []);

    return (
        <Card style={{ width: '100%' }}>
            <Card.Header>
                Editing...
            </Card.Header>
            <Card.Body>
                <Form>
                    <FloatingLabel label="Name" className="mb-3" controlId="formUserName">
                        <Form.Control type="text" defaultValue={data.nickname} onChange={e => setName(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Email" className="mb-3" controlId="formUserEmail">
                        <Form.Control type="text" defaultValue={data.email} readOnly />
                    </FloatingLabel>
                    <FloatingLabel label="Child" className="mb-3" controlId="formChildId">
                        <Form.Control type="text" defaultValue={data.child} onChange={e => setChild(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Role" className="mb-3" controlId="formUserRole">
                        <Form.Select aria-label="Default select example" defaultValue={data.role} onChange={e => setRole(e.target.value)}>
                            <option>Select Role</option>
                            <option value={UserType.Admin}>{UserType.Admin}</option>
                            <option value={UserType.Teacher}>{UserType.Teacher}</option>
                            <option value={UserType.Parent}>{UserType.Parent}</option>
                        </Form.Select>
                    </FloatingLabel>
                </Form>
                <ButtonGroup aria-label="Basic example" size="sm">
                    <Button variant="success" onClick={() => {
                        saveCallback({
                            user_id: data.username,
                            user_name: Name,
                            child: Child,
                            user_role: Role
                        });
                        cancelCallback();
                    }}>Save</Button>
                    <Button variant="light" onClick={cancelCallback}>Cancel</Button>
                    {/* <Button variant="danger" onClick={() => deleteCallback(data.username)}>Delete</Button> */}
                </ButtonGroup>
            </Card.Body>
        </Card>
    );
}

const EditUserInfoModal = (props) => {
    const [Name, setName] = useState();
    const [Children, setChildren] = useState([]);
    const [Role, setRole] = useState();

    const { StudentList } = ObtainSessionStorageData();

    useEffect(() => {
        setName(props.data.nickname);
        setChildren(props.data.children);
        setRole(props.data.role);
    }, []);

    return (
        <Modal {...props} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Editing User - {props.data.email}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FloatingLabel label="User Name" className="mb-3" controlId="formUserName">
                        <Form.Control type="text" defaultValue={props.data.nickname} onChange={e => setName(e.target.value)} />
                    </FloatingLabel>
                    <ArrayBuilder label="Add Students" controlId="formUserStudentArray" placeholderOption="Select Students" optionList={StudentList} defaultValue={Children} updateCallback={setChildren} />
                    <FloatingLabel label="Role" className="mb-3" controlId="formUserRole">
                        <Form.Select aria-label="Default select example" defaultValue={props.data.role} onChange={e => setRole(e.target.value)}>
                            <option>Select Role</option>
                            <option value={UserType.Admin}>{UserType.Admin}</option>
                            <option value={UserType.Teacher}>{UserType.Teacher}</option>
                            <option value={UserType.Parent}>{UserType.Parent}</option>
                        </Form.Select>
                    </FloatingLabel>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup aria-label="Basic example">
                    <Button variant="success" onClick={() => {
                        props.saveCallback({
                            user_id: props.data.username,
                            user_name: Name,
                            children: Children,
                            user_role: Role
                        });
                        props.onHide();
                    }}>Save</Button>
                    <Button variant="light" onClick={props.onHide}>Cancel</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

export const CreateUserInfoModal = (props) => {
    const [Email, setEmail] = useState();
    const [FirstName, setFirstName] = useState();
    const [LastName, setLastName] = useState();
    const [PhoneNumber, setPhoneNumber] = useState();
    const [Role, setRole] = useState();

    return (
        <Modal {...props} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Creating New User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FloatingLabel label="Email" className="mb-3" controlId="formUserEmail">
                        <Form.Control type="email" placeholder="email@email.com" onChange={e => setEmail(e.target.value)} autoFocus />
                    </FloatingLabel>
                    <FloatingLabel label="First Name" className="mb-3" controlId="formUserFirstName">
                        <Form.Control type="text" placeholder="First Name" onChange={e => setFirstName(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Last Name" className="mb-3" controlId="formUserLastName">
                        <Form.Control type="text" placeholder="Last Name" onChange={e => setLastName(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Phone Number" className="mb-3" controlId="formUserPhoneNumber">
                        <Form.Control type="tel" placeholder="0000000000" onChange={e => setPhoneNumber(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Role" className="mb-3" controlId="formUserRole">
                        <Form.Select aria-label="Default select example" onChange={e => setRole(e.target.value)}>
                            <option>Select Role</option>
                            <option value={UserType.Admin}>{UserType.Admin}</option>
                            <option value={UserType.Teacher}>{UserType.Teacher}</option>
                            <option value={UserType.Parent}>{UserType.Parent}</option>
                        </Form.Select>
                    </FloatingLabel>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup aria-label="Basic example">
                    <Button variant="success" onClick={() => {
                        props.saveCallback({
                            email: Email,
                            first_name: FirstName,
                            last_name: LastName,
                            phone_number: PhoneNumber,
                            role: Role
                        });
                        props.onHide();
                    }}>Create</Button>
                    <Button variant="light" onClick={props.onHide}>Cancel</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

const UserInfo = ({ data, saveCallback, deleteCallback }) => {

    const [Editing, setEditing] = useState(false);

    const onSendMagicLink = async (user_email) => {
        await sendUserMagicLink(user_email);
    }

    return (
        <>
            <EditUserInfoModal data={data} show={Editing} onHide={() => setEditing(false)} saveCallback={saveCallback} />
            <DisplayUserInfoTableRow
                data={data}
                editCallback={() => setEditing(true)}
                magicLinkCallback={() => onSendMagicLink(data.email)} />
        </>
    )
};

export default UserInfo;