import React, { useState, useEffect } from 'react';

/* Stytch */
import { useStytchUser } from '@stytch/react';

/* Component */
import Message, { CreateMessageModal, DisplayMessageTableRow } from '../components/Message';

/* Function */
import { fetchAllMessagesForUserId, createMessage, deleteMessageById } from '../functions/MessageFunction';

/* UI */
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { fetchAllUsers } from '../functions/UserProfileFunction';

/* message page */
const MessagePage = () => {
  const { user } = useStytchUser();
  // messages
  const [Messages, setMessages] = useState([]);
  const [Users, setUsers] = useState([]);

  const [NewMessageModalShow, setNewMessageModalShow] = useState(false);

  useEffect(() => {
    const initialAllMessagesFetch = async () => {
      await fetchAllMessagesForUserId(user.user_id).then(
        result => {
          setMessages(result);
        }
      );
    }

    const initialAllUsersFetch = async () => {
      await fetchAllUsers().then(
        result => {
          setUsers(result);
        }
      )
    }

    initialAllMessagesFetch();
    initialAllUsersFetch();
  }, [user]);

  // const UploadNewMessage = async (formData) => {
  //   const MessageNumber = Math.round(Math.random() * 1000);
  //   formData.append('id', MessageNumber.toString());
  //   formData.append('time', new Date().toLocaleString());

  //   try {
  //     await axios(
  //       {
  //         method: "post",
  //         url: process.env.REACT_APP_AWS_MESSAGES,
  //         data: formData,
  //         headers: { "Content-Type": "multipart/form-data" },
  //       })
  //       .then(response => {
  //         console.log(response);
  //       })
  //   } catch (error) {
  //     console.error("Error uploading new message.", error);
  //   }
  // }

  const onCreatingMessage = async (props) => {
    props.message_sender = user.user_id;
    await createMessage(props);
    await fetchAllMessagesForUserId(user.user_id).then(result => setMessages(result));
  }

  const onDeletingMessage = async (message_id) => {
    await deleteMessageById(message_id);
    await fetchAllMessagesForUserId(user.user_id).then(result => setMessages(result));
  }

  const user_list = [];
  if (Array.isArray(Users)) {
    Users.forEach(
      user => {
        user_list.push(
          {
            key: user.username,
            text: user.nickname
          }
        )
      }
    )
  }

  return (
    <Container fluid>
      <hr />
      <h3>Message List</h3>
      <hr />
      <Button variant="primary" onClick={() => setNewMessageModalShow(true)}>New Message</Button>
      <CreateMessageModal
        show={NewMessageModalShow}
        onHide={() => setNewMessageModalShow(false)}
        saveCallback={onCreatingMessage}
        userList={user_list}
      />
      <Table striped responsive>
        <thead>
          <tr>
            <th>Time</th>
            <th>From</th>
            <th>Subject</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Messages.length > 0 ?
            Messages.map(
              message => {
                return <DisplayMessageTableRow key={message.messageId} data={message} deleteCallback={onDeletingMessage} userList={user_list} />
              })
            :
            <tr><td>Cannot find any messages.</td></tr>
          }
        </tbody>
      </Table>
    </Container>
  )
};

export default MessagePage;