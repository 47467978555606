import axios from 'axios';

import PostActionType from "../types/PostActionType";

const headers = {
    'Content-Type': 'text/plain'
};

export const fetchAllClassrooms = async () => {
    try {
        const url = process.env.REACT_APP_AWS_CLASSROOMS;
        const response = await fetch(url);
        const result = await response.json();

        if (Array.isArray(result)) {
            return result;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching all classrooms:', error);
    }
}

export const fetchClassroomById = async (class_id) => {
    try {
        const url = process.env.REACT_APP_AWS_CLASSROOMS.concat("?", "id=", class_id.toString());
        const response = await fetch(url);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error fetching all classrooms:', error);
    }
}

export const createClassroom = async (props) => {
    const ClassNumber = Math.round(Math.random() * 1000);
    try {
        await axios.post(
            process.env.REACT_APP_AWS_CLASSROOMS,
            {
                'id': ClassNumber.toString(),
                'name': props.class_name,
                'location': props.class_location,
                'teachers': props.class_teachers,
                'students': props.class_students
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error("Error creating a classroom", error);
    }
}

export const updateClassroom = async (props) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_CLASSROOMS,
            {
                'id': props.class_id,
                'action': PostActionType.Update,
                'name': props.class_name,
                'location': props.class_location,
                'teachers': props.class_teachers,
                'students': props.class_students
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error("Error updating a classroom", error);
    }
}

export const deleteClassroomById = async (class_id) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_CLASSROOMS,
            {
                'id': class_id,
                'action': PostActionType.Delete
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error("Error deleting classroom [" + class_id + "]", error);
    }
}