import axios from 'axios';

/* Type */
import PostActionType from "../types/PostActionType";

const headers = {
    'Content-Type': 'text/plain'
};

export const fetchAllEvents = async () => {
    try {
        const url = process.env.REACT_APP_AWS_EVENTS;
        const response = await fetch(url);
        const result = await response.json();

        if (Array.isArray(result)) {
            return result;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching events:', error);
    }
};

export const fetchAllEventsByStudentIds = async (student_list) => {
    if (!Array.isArray(student_list)) {
        return [];
    }

    let studentStrings = student_list.join("+");

    try {
        const url = process.env.REACT_APP_AWS_EVENTS.concat('?students=', studentStrings);
        const response = await fetch(url);
        const result = await response.json();

        if (Array.isArray(result)) {
            return result;
        } else {
            return [];
        }
    } catch (error) {
        console.error(`Error fetching events for ${studentStrings}:`, error);
    }
}

export const createEventWithParams = async (props) => {
    try {
        const EventNumber = (Date.now() * 1000) + (Math.floor(Math.random() * 999));

        await axios.post(
            process.env.REACT_APP_AWS_EVENTS,
            {
                'id': "Event_" + EventNumber.toString(),
                'type': props.event_type,
                'description': props.event_description,
                'time': new Date().toLocaleString(),
                'student': props.student_id
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error('Error creating event:', error);
    }
}

export const deleteEvent = async (event_id) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_EVENTS,
            {
                'id': event_id,
                'action': PostActionType.Delete
            },
            { headers })
            .then(response => {
                return response;
            })
    } catch (error) {
        console.error('Error deleting event:', error);
    }
}