import React, { useEffect, useState } from "react";

/* Function */
import { ConcatListToString, ObtainSessionStorageData } from "../functions/SessionStorageFunction";

/* UI */
import ArrayBuilder from "./ArrayBuilder";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const DisplayClassroomTableRow = ({ data, editCallback }) => {

    const { StudentList, TeacherList} = ObtainSessionStorageData();

    return (
        <tr>
            <td>{data ? data.name : ''}</td>
            <td>{data ? data.location : ''}</td>
            <td>{data ? ConcatListToString(TeacherList, data.teachers) : ''}</td>
            <td>{data ? ConcatListToString(StudentList, data.students) : ''}</td>
            <td>
                <ButtonGroup size="sm">
                    <Button variant="light" onClick={editCallback}>Edit</Button>
                </ButtonGroup>
            </td>
        </tr>
    );
}

const EditClassroomModal = (props) => {
    const [Name, setName] = useState();
    const [Location, setLocation] = useState();
    const [Teachers, setTeachers] = useState([]);
    const [Students, setStudents] = useState([]);

    useEffect(() => {
        setName(props.data.name);
        setLocation(props.data.location);

        let TeacherList = JSON.parse(props.data.teachers);
        setTeachers(Array.isArray(TeacherList) ? TeacherList : []);

        let StudentList = JSON.parse(props.data.students);
        setStudents(Array.isArray(StudentList) ? StudentList : []);
    }, []);

    const { StudentList, TeacherList} = ObtainSessionStorageData();

    return (
        <Modal {...props} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Editing Classroom - {Name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FloatingLabel label="Class Name" className="mb-3" controlId="formClassName">
                        <Form.Control type="text" defaultValue={props.data.name} onChange={e => setName(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Class Location" className="mb-3" controlId="formClassLocation">
                        <Form.Control type="text" defaultValue={props.data.location} onChange={e => setLocation(e.target.value)} />
                    </FloatingLabel>
                    <ArrayBuilder label="Add Teacher" controlId="formClassTeacherArray" placeholderOption="Select Teacher" optionList={TeacherList} defaultValue={Teachers} updateCallback={setTeachers} />
                    <ArrayBuilder label="Add Student" controlId="formClassStudentArray" placeholderOption="Select Student" optionList={StudentList} defaultValue={Students} updateCallback={setStudents} />
                    {/* <FloatingLabel label="Teachers" className="mb-3" controlId="formClassTeachers">
                        <Form.Control type="text" defaultValue={JSON.stringify(props.data.teachers)} onChange={e => setTeachers(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Students" className="mb-3" controlId="formClassStudents">
                        <Form.Control type="text" defaultValue={JSON.stringify(props.data.students)} onChange={e => setStudents(e.target.value)} />
                    </FloatingLabel> */}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup aria-label="Basic example">
                    <Button variant="success" onClick={() => {
                        props.saveCallback({
                            class_id: props.data.classId,
                            class_name: Name,
                            class_location: Location,
                            class_teachers: JSON.stringify(Teachers),
                            class_students: JSON.stringify(Students)
                        });
                        props.onHide();
                    }}>Save</Button>
                    <Button variant="light" onClick={props.onHide}>Cancel</Button>
                    <Button variant="danger" onClick={() => {
                        props.deleteCallback(props.data.classId)
                    }}>Delete</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

export const CreateClassroomModal = (props) => {
    const [Name, setName] = useState();
    const [Location, setLocation] = useState();
    const [Teachers, setTeachers] = useState();
    const [Students, setStudents] = useState();

    const { StudentList, TeacherList} = ObtainSessionStorageData();

    return (
        <Modal {...props} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Creating New Classroom
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FloatingLabel label="Class Name" className="mb-3" controlId="formClassName">
                        <Form.Control type="text" placeholder="Class Name" onChange={e => setName(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Class Location" className="mb-3" controlId="formClassLocation">
                        <Form.Control type="text" placeholder="Class Location" onChange={e => setLocation(e.target.value)} />
                    </FloatingLabel>

                    <ArrayBuilder label="Add Teacher" controlId="formClassTeacherArray" placeholderOption="Select Teacher" optionList={TeacherList} updateCallback={setTeachers} />
                    <ArrayBuilder label="Add Student" controlId="formClassStudentArray" placeholderOption="Select Student" optionList={StudentList} updateCallback={setStudents} />

                    {/* <FloatingLabel label="Teachers" className="mb-3" controlId="formClassTeachers">
                        <Form.Control type="text" placeholder="[teacher, teacher]" onChange={e => setTeachers(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label="Students" className="mb-3" controlId="formClassStudents">
                        <Form.Control type="text" placeholder="[student, student]" onChange={e => setStudents(e.target.value)} />
                    </FloatingLabel> */}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup aria-label="Basic example">
                    <Button variant="success" onClick={() => {
                        props.saveCallback({
                            class_name: Name,
                            class_location: Location,
                            class_teachers: JSON.stringify(Teachers),
                            class_students: JSON.stringify(Students)
                        });
                        props.onHide();
                    }}>Save</Button>
                    <Button variant="light" onClick={props.onHide}>Cancel</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

const Classroom = ({ data, saveCallback, deleteCallback }) => {

    const [Editing, setEditing] = useState(false);

    return (
        <>
            <EditClassroomModal
                data={data}
                show={Editing}
                onHide={() => setEditing(false)}
                saveCallback={saveCallback}
                deleteCallback={deleteCallback} />
            <DisplayClassroomTableRow
                data={data}
                editCallback={() => setEditing(true)} />
        </>
    )
};

export default Classroom;