import axios from 'axios';

import PostActionType from "../types/PostActionType";

const headers = {
    'Content-Type': 'text/plain'
};

export const createNewUser = async (props) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_NEW_USER,
            {
                email: props.email,
                first_name: props.first_name,
                last_name: props.last_name,
                phone_number: props.phone_number,
                role: props.role
            }, { headers })
            .then(response => {
                return response;
            });
    } catch (error) {
        console.error('Error creating new user:', error);
    }
}

export const createUserProfile = async (user_id, user_email, user_name, user_role, complete_callback) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_USER_PROFILE,
            {
                'id': user_id,
                'nickname': user_name,
                'email': user_email,
                'role': user_role,
            },
            { headers })
            .then(response => {
                if (response.status === 200) {
                    complete_callback();
                }
            })
    } catch (error) {
        console.error('Error creating user profile:', error);
    }
}

export const fetchAllUsers = async () => {
    try {
        const url = process.env.REACT_APP_AWS_USER_PROFILE.concat("?", "id=all");
        const response = await fetch(url);
        const result = await response.json();

        if (Array.isArray(result)) {
            return result;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchUserProfile = async (user_id) => {
    try {
        const url = process.env.REACT_APP_AWS_USER_PROFILE.concat("?", "id=", user_id);
        const response = await fetch(url);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const saveUserProfile = async (props) => {
    console.log(props);
    if (!Array.isArray(props.children)) {
        props.children = [];
    }
    try {
        await axios.post(
            process.env.REACT_APP_AWS_USER_PROFILE,
            {
                'action': PostActionType.Update,
                'id': props.user_id,
                'nickname': props.user_name,
                'role': props.user_role,
                'children': props.children
            }, { headers })
            .then(response => {
                return response;
            });
    } catch (error) {
        console.error('Error updating user info:', error);
    }
}

export const deleteUserById = async (user_id) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_USER_PROFILE,
            {
                action: PostActionType.Delete,
                id: user_id
            }, { headers })
            .then(response => {
                return response;
            });
    } catch (error) {
        console.error('Error deleting user info:', error);
    }
}

export const sendUserMagicLink = async (user_email) => {
    try {
        await axios.post(
            process.env.REACT_APP_AWS_SEND_MAGIC_LINK,
            {
                email: user_email
            }, { headers })
            .then(response => {
                if (response.status === 200) {
                    alert(`Magic link sent to ${user_email}`);
                }
            });
    } catch (error) {
        console.error('Error sending user magic link:', error);
    }
}